<template>
  <div>
    <users></users>
  </div>
</template>

<script>
  import Users from '../../components/Users/Users';

  export default {
    name: 'Users',
    components: {
      users: Users
    },
    data() {
      return {

      };
    }
  };
</script>
